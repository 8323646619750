import React from 'react';
import {
  Segment,
  Header,
  Button,
  Image,
  Label,
  Loader,
  Message,
} from 'semantic';
import { screen } from 'helpers';
import Menu from './Menu';
import 'react-tectonic/lib/styles.css';
import { precisionForUnit } from 'utils/devices';
import { startCase } from 'lodash';
import { TECTONIC_URL } from 'utils/env';

import { request } from 'utils/api';
import {
  TectonicProvider,
  Aggregate,
  AggregateTimeSeries,
  SeriesChart,
  MultiSeriesChart,
  TimeRangePicker,
} from 'react-tectonic';
import { HelpTip } from 'components';

export const defaultColors = [
  '#247870',
  '#2185d0',
  '#00b5ad',
  '#21ba45',
  '#b5cc18',
  '#fbbd08',
  '#f2711c',
  '#db2828',
  '#e03997',
  '#a333c8',
  '#6435c9',
];

function combineGroupedMetrics(metrics) {
  let combinedMetrics = [];
  const groups = {};
  metrics.forEach((metric) => {
    if (metric.displayAnalytics && metric.displayAnalytics.group) {
      const { group } = metric.displayAnalytics;
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(metric);
    } else {
      combinedMetrics.push(metric);
    }
  });
  Object.keys(groups).forEach((group) => {
    combinedMetrics.push({
      type: 'multi-time-series',
      group,
      path: group,
      metrics: groups[group],
      displayPriority: groups[group][0].displayPriority,
    });
  });
  combinedMetrics = combinedMetrics.sort((itemA, itemB) => {
    return (itemB.displayPriority || 1) - (itemA.displayPriority || 1);
  });
  return combinedMetrics;
}

function listDisplayMetrics(monitoring, path = []) {
  let metrics = [];
  const keys = Object.keys(monitoring);
  keys.forEach((key) => {
    if (monitoring[key].type === 'object' && monitoring[key].properties) {
      metrics = metrics.concat(
        listDisplayMetrics(monitoring[key].properties, [key])
      );
      return;
    }
    if (monitoring[key].displayAnalytics) {
      metrics.push({
        ...monitoring[key],
        path: path.concat([key]).join('.'),
        type: 'time-series',
      });
    }
  });
  metrics = metrics.sort((itemA, itemB) => {
    return itemB.displayPriority - itemA.displayPriority;
  });
  return combineGroupedMetrics(metrics);
}

function headerForMetric(metric) {
  const { type, path, name, description } = metric;
  let title = path;
  if (type === 'time-series') {
    const pathArray = path.split('.');
    title = name.en;
    if (pathArray.length > 1) {
      const sectionName = startCase(pathArray[0]);
      if (!title.toLowerCase().match(sectionName.toLowerCase())) {
        title = `${sectionName} / ${title}`;
      }
    }
  }
  return (
    <Header as="h4" textAlign="center" style={{ marginBottom: '10px' }}>
      {title}
      {description && <HelpTip text={description.en} />}
    </Header>
  );
}

function valueFormatterForMetric(unit) {
  return (value) => {
    const precision = precisionForUnit[unit] || 2;
    let roundedValue = value.toFixed(precision);
    if (precision == 0) {
      roundedValue = Math.round(value);
    }
    if (unit === 'minutes') {
      return `${roundedValue} min`;
    }
    if (unit === 'celcius') {
      return `${roundedValue} °C`;
    }
    return `${roundedValue}${unit}`;
  };
}

function renderTimeSeries(device, metric, title) {
  return (
    <AggregateTimeSeries
      filter={{
        terms: [
          {
            'source.deviceId': device.id,
          },
        ],
      }}
      operation={metric.displayAnalytics.operations[0]}
      field={`${metric.path}`}>
      <SeriesChart
        title={title}
        chartType="area"
        height={250}
        valueField="value"
        valueFieldLabel={metric.name.en}
        valueFormatter={valueFormatterForMetric(metric.unit)}
        color={defaultColors[0]}
      />
    </AggregateTimeSeries>
  );
}

function renderMultiTimeSeries(device, { metrics, group }, title) {
  const filter = {
    terms: [
      {
        'source.deviceId': device.id,
      },
    ],
  };
  const { unit } = metrics[0];

  return (
    <Aggregate
      type="time-series"
      requests={metrics.map((metric) => {
        return {
          filter,
          operation: metric.displayAnalytics.operations[0],
          field: `${metric.path}`,
        };
      })}>
      <MultiSeriesChart
        chartType="line"
        title={title}
        height={250}
        valueField="value"
        valueFormatter={valueFormatterForMetric(unit)}
        color={defaultColors[0]}
        labels={metrics.map((metric) => metric.name.en)}
      />
    </Aggregate>
  );
}

@screen
export default class DeviceAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchToken();
  }

  async fetchToken() {
    const { device } = this.props;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'POST',
        path: `/1/systems/${device.system.id}/analytics-token`,
      });
      const { token } = data;
      this.setState({
        token,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  renderAnalytics(token) {
    if (!token) {
      return <Loader label="Loading Analytics" />;
    }
    const { device } = this.props;
    const metrics = listDisplayMetrics(device.monitoring);
    return (
      <TectonicProvider
        baseUrl={TECTONIC_URL}
        token={token}
        primaryColor="#247870"
        collection="monitoring-events"
        dateField="_tectonic.ingestedAt">
        <React.Fragment>
          <TimeRangePicker
            renderButton={(label, handleOnClick) => (
              <Button icon="clock" content={label} onClick={handleOnClick} />
            )}
          />
          {metrics.map((metric) => {
            return (
              <Segment basic key={metric.path}>
                {metric.type === 'time-series' &&
                  renderTimeSeries(device, metric, headerForMetric(metric))}
                {metric.type === 'multi-time-series' &&
                  renderMultiTimeSeries(
                    device,
                    metric,
                    headerForMetric(metric)
                  )}
              </Segment>
            );
          })}
        </React.Fragment>
      </TectonicProvider>
    );
  }
  render() {
    const { device } = this.props;
    const { token } = this.state;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Message
          info
          icon="flask"
          content="Note: This feature is under active development."
        />
        {this.renderAnalytics(token)}
      </React.Fragment>
    );
  }
}
